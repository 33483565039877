import { Areas, FileTags, GlobalLabel, ItemStatus } from 'app/types';
import { ReportReference } from '../../reports/types';
import { User } from '../../permission-wrapper/types';
import { Property } from 'app/admin-layout/pages/properties/types';
// import { ReportReference } from "../../reports/types";
export enum DocumentShareStatus {
  SUBMITTED = 'SUBMITTED',
  INPROGRESS = 'INPROGRESS',
  EXPIRED = 'EXPIRED',
}
export enum OfflineList {
  SELECTED = 'documentSelectedList',
  ALL = 'documentList',
}
export interface Log {
  updated_at: UserActivity;
  created_at: UserActivity;
}
export interface UserActivity {
  id: number;
  action: 'created' | 'updated';
  user_email: string;
  date: string;
}
export interface DocumentTemplate {
  name: string;
  data: Areas;
  uuid: string;
  info: DocumentTemplateInfo;
  log?: Log;
}
export interface DocumentTemplateInfo {
  config: DocumentTemplateConfig;
}
export interface DocumentTemplateConfig {
  description: boolean;
  imageTags: Array<FileTags>;
  categoryTags: Array<FileTags>;
}
export interface DocumentTemplateLink extends Omit<DocumentTemplate, 'data'> {}
export interface AssessmentType {
  id: number;
  name: string;
  description?: string;
  allowTenantShare: boolean;
}
export enum UploadImageStatusEnum {
  UPLOADED = 'UPLOADED',
  NOT_UPLOADED = 'NOT_UPLOADED',
  OFFLINE = 'OFFLINE',
}
export interface GetDocumentListResponse {
  data: Array<DocumentList>;
  total: number;
  lastPage: number;
}
export type RedirectUrl = 'property' | 'document-list';
export type DocumentType = 'assessment' | 'work-order';
export interface Document {
  id?: number;
  uuid: string;
  document_uuid: string;
  property_id: number;
  labels?: Array<GlobalLabel>;
  info: DocumentInfo;
  data: Areas;
  token: string;
  type: DocumentType;
  created_at?: string;
  updated_at?: string;
  shared_document?: SharedDocument;
  assigned_users: Array<User>;
  log?: Log;
}
export interface SharedDocument {
  uuid: string;
  property_id: number;
  document_uuid: string;
  info: DocumentInfo;
  type: DocumentType;
  token: string;
}
export interface ShareDocument {
  uuid: string;
  token: string;
}
export interface DocumentListConfig {
  returnBack: boolean;
  filterProperty?: boolean;
  create: boolean;
  redirectUrl: RedirectUrl;
}
export interface DocumentProperty {
  property_id: number;
  name: string;
}
export interface DocumentList extends Omit<Document, 'data'> {}

export interface DocumentInfo {
  name: string;
  notes: string;
  property_id?: number;
  reference?: ReportReference;
  sharedInfo: DocumentShareInfo | null;
  assessmentType?: AssessmentType;
  documentType: DocumentType;
  template_id: string;
  templateConfig: DocumentTemplateConfig | null;
  token: boolean;
  notification: boolean;
  uploadImageStatus: UploadImageStatus;
  isOffline?: boolean;
}
export interface DocumentTemplateConfig {
  description: boolean;
  imageTags: Array<FileTags>;
  categoryTags: Array<FileTags>;
  statusList: Array<ItemStatus>;
}
export interface DocumentShareInfo {
  status: DocumentShareStatus | null;
  time_inprogress: number | null;
  time_submitted: number | null;
  expire_start_date: number;
  expire_end_date: number;
  fullName: string;
  email: string;
  propertyName: string;
  propertyManager: string;
}
export interface UploadImageStatus {
  uploaded: number | null;
  size: number | null;
  status: UploadImageStatusEnum;
}
export interface DocumentEdit {
  labels?: Array<GlobalLabel>;
  info?: DocumentInfo;
  data?: Areas;
}

export interface DocumentDropdownAction {
  name: string;
  icon: string;
  color?: string;
  action?: Function;
}
export interface DocumentViewFilter {
  status: Array<ItemStatus>;
  byValue: boolean;
  active: boolean;
}
