import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule, ModalController } from '@ionic/angular';
import { AssessmentType, DocumentInfo, DocumentTemplateLink, DocumentType, UploadImageStatusEnum } from '../types';
import { CommonModule } from '@angular/common';
import { Observable, firstValueFrom } from 'rxjs';
import { NetworkState } from 'app/shared/state/network/network.state';
import { Select, Store } from '@ngxs/store';
import { Document as DocumentAction } from '../state/document.actions';
import { DocumentService } from '../services/document.service';

@Component({
  selector: 'create-document-modal',
  templateUrl: 'create-document.component.html',
  standalone: true,
  imports: [FormsModule, CommonModule, IonicModule],
})
export class ModalCreateDocumentComponent {
  private store = inject(Store);
  private modalCtrl = inject(ModalController);
  private documentService = inject(DocumentService);
  @Select(NetworkState.isConnected) connected$: Observable<boolean>;
  public templates: Array<DocumentTemplateLink> = [];
  public assessmentTypes: Array<AssessmentType> = [];
  public customAssessmentType: AssessmentType = {
    id: 100000,
    name: 'Custom',
    allowTenantShare: false,
    description: 'custom',
  };
  public name: string;
  public type: DocumentType;
  public selectedTemplate: DocumentTemplateLink;
  public selectedAssessmentType: AssessmentType;
  public isValid: boolean = false;
  ionViewWillEnter() {
    this.selectedTemplate = this.templates.find(template => template.uuid === 'null');
  }
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
  validate(input: string | AssessmentType) {
    if (typeof input === 'string') {
      this.isValid = input?.length > 0;
    } else {
      this.isValid = input.name?.length > 0;
    }
  }
  async confirm() {
    const network = await firstValueFrom(this.connected$);
    const assessmentType = this.selectedAssessmentType ?? this.customAssessmentType;
    let documentInfo: DocumentInfo = {
      name: this.name ?? assessmentType.name,
      template_id: this.selectedTemplate.uuid,
      templateConfig: null,
      documentType: this.type,
      notes: '',
      token: false,
      notification: false,
      sharedInfo: null,
      assessmentType: this.type === 'assessment' ? assessmentType : null,
      uploadImageStatus: {
        uploaded: null,
        size: null,
        status: network ? UploadImageStatusEnum.UPLOADED : UploadImageStatusEnum.OFFLINE,
      },
    };
    this.store.dispatch(new DocumentAction.DocumentCreate(documentInfo, this.documentService.selectedDocument));
    return this.modalCtrl.dismiss();
  }
}
