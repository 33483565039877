import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { TabsPage } from './shared/pages/tabs/tabs.page';

const routes: Routes = [
  {
    path: '',
    component: TabsPage,
    children: [
      { path: '', redirectTo: 'auth/home', pathMatch: 'full' },
      {
        path: 'admin',
        component: AdminLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
          },
        ],
        canActivate: [AuthGuard],
        // outlet: 'admin'
      },
      {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
          },
        ],
        // outlet: 'auth'
      },
    ],
  },
  { path: '**', redirectTo: 'auth/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
