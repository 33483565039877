import { Area, Areas } from '../../types';
import { PropertyAppliances, PropertyItems } from 'app/admin-layout/pages/properties/types';
const arealist = [
  'Front Yard Exterior',
  'Back Yard Exterior',
  'Entry',
  'Living Room',
  'Dining Room',
  'Breakfast Area',
  'Study/Office',
  'Family Room',
  'Kitchen',
  'Laundry Room',
  'Half Bath # 1',
  'Half Bath # 2',
  'Master Bathroom',
  'Full Bathroom # 2',
  'Full Bathroom # 3',
  'Full Bathroom # 4',
  'Garage',
  'Hallway / Stairs',
  'Hallway / Stairs #2',
  'Master Bedroom',
  'Bedroom #2',
  'Bedroom #3',
  'Bedroom #4',
  'Bedroom #5',
  'Game Room',
  'Media Room',
  'Loft',
  'Other Room',
  'Attic',
  'Appliances',
];
const areaItemsList = [
  {
    name: 'Front Yard Exterior',
    items: [
      'Building Exterior',
      'Landscaping',
      'Garage Door Exterior',
      'Driveway And Walkway',
      'Electrical Items',
      'Signs Of Erosion/Drainage',
      'Fence/Gate',
      'Gutters',
      'Sprinkler',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Back Yard Exterior',
    items: [
      'Building Exterior',
      'Landscaping',
      'Patio/Deck/Balcony',
      'Door Keyless Lock & Re-keyable Deadbolt',
      'Sliding Door Secondary Lock',
      'Electrical Items',
      'Signs Of Erosion/Drainage',
      'Fence/Gate',
      'Gutters',
      'Sprinkler',
      'Pet Evidence',
      'Misc.. (Shed, Pool, Etc…)',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Entry',
    items: [
      'Porch/Patio',
      'Front Door Exterior & Trim',
      'Door Keyless Lock & Re-keyable Deadbolt',
      'Peephole',
      'Doorbell',
      'Front Door Interior & Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Living Room',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Dining Room',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Breakfast Area',
    items: [
      'Breakfast Area',
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Study/Office',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Family Room',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Kitchen',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Counter Top / Back Splash',
      'Sink / Faucet',
      'Lower Cabinets',
      'Upper Cabinets',
      'Cabinet Under Sink',
      'Interior Cabinets / Drawers',
      'Ceiling',
      'HVAC Supply Vent',
      'HVAC Return / Filter Location & Size',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Pantry',
      'Fridge',
      'OTR Vent',
      'OTR Microwave',
      'Microwave (Built-in)',
      'Range / Cooktop / Oven',
      'Oven (Built-in)',
      'Dishwasher',
      'Garbage Disposal',
      'Reverse Osmosis / Filter System',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Laundry Room',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      ' Washer Connections',
      'Dryer Vent',
      'Washer',
      'Dryer',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Half Bath # 1',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'Sink / Faucet',
      'Cabinet Under Sink',
      'Commode',
      'Exhaust Fan',
      'Towel Bars',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Half Bath # 2',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'Sink / Faucet',
      'Cabinet Under Sink',
      'Commode',
      'Exhaust Fan',
      'Towel Bars',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Master Bathroom',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'Sink / Faucet',
      'Cabinet Under Sink',
      'Commode',
      'Exhaust Fan',
      'Tub / Fixture',
      'Shower / Fixture',
      'Towel Bars',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Full Bathroom # 2',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'Sink / Faucet',
      'Cabinet Under Sink',
      'Commode',
      'Exhaust Fan',
      'Tub / Fixture',
      'Shower / Fixture',
      'Towel Bars',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Full Bathroom # 3',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'Sink / Faucet',
      'Cabinet Under Sink',
      'Commode',
      'Exhaust Fan',
      'Tub / Fixture',
      'Shower / Fixture',
      'Towel Bars',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Full Bathroom # 4',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'Sink / Faucet',
      'Cabinet Under Sink',
      'Commode',
      'Exhaust Fan',
      'Tub / Fixture',
      'Shower / Fixture',
      'Towel Bars',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Garage',
    items: [
      'Door And Trim',
      'Keyless Lock & Re-keyable Deadbolt',
      'Peephole',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Garage Door Opener',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Hallway / Stairs',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Hallway / Stairs #2',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Master Bedroom',

    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Bedroom #2',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Bedroom #3',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Bedroom #4',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Bedroom #5',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },

  {
    name: 'Game Room',

    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Surround Sound System',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Media Room',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Surround Sound System',
      'Miscellaneous Issues',
    ],
  },

  {
    name: 'Loft',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Surround Sound System',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Other Room',
    items: [
      'Door And Trim',
      'Door Stops',
      'Flooring / Baseboards',
      'Walls',
      'Ceiling',
      'HVAC Supply Vent',
      'Window Covering / Blinds',
      'Window / Locks / Screen',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'Closets',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Surround Sound System',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Attic',
    items: [
      'Door And Trim',
      'Door Stops',
      'Ceiling',
      'HVAC Supply Vent',
      'Switch And Outlets',
      'Light Fixture / Fan',
      'HVAC Return / Filter Location & Size',
      'Smoke Alarm',
      'Carbon Monoxide Alarm',
      'Miscellaneous Issues',
    ],
  },
  {
    name: 'Appliances',
    items: [
      'Water Softener / Salt Levels',
      'Water Heater',
      'Water Heater #2',
      'HVAC',
      'HVAC # 2',
      'Thermostat',
      'Thermostat # 2',
      'Other Appliance',
      'Other Applicance # 2',
    ],
  },
];

export const _defaultAreas: Array<Area> = areaItemsList.map(area => {
  return {
    areaName: area.name,
    areaData: {
      notes: null,
      files: [],
    },
    items: area.items.map(item => {
      return {
        itemName: item,
        itemStatus: [],
        jobCost: {
          total: 0,
          landlord: 0,
          tenant: 0,
        },
        workStatus: {
          status: null,
          taskStatus: null,
          completedDate: null,
          files: [],
          comments: null,
        },
        comments: [],
        notes: null,
        files: [],
      };
    }),
  };
});
export const defaultAreas: Areas = {
  areas: _defaultAreas,
};
export const defaultAppliances: Array<PropertyAppliances> = [
  {
    name: 'Fridge',
    life: 12,
    make: 'Whirlpool',
    model: 'EDFHGXNQ00',
    notes: null,
    modelYear: 2006,
    replaceCost: 1500,
    serialNumber: 'SR2836905',
  },
  {
    name: 'Range/Cooktop/Oven',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Washing Machine',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Dryer',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'HVAC #1',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'HVAC #2',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Water Softener',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Water Heater #1',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Water Heater #2',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Garbage Disposal',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'OTR Microwave',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'OTR Vent',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Dish Washer',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Garage Door Opener',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Surround System',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Sprinkler System',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Oven (Built-in)',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
  {
    name: 'Reverse Osmosis/Filter System',
    life: null,
    make: null,
    model: null,
    notes: null,
    modelYear: null,
    replaceCost: null,
    serialNumber: null,
  },
];
export const defaultAdditionalItems: Array<PropertyItems> = [
  {
    name: 'Carpet/Flooring',
    life: 8,
    modelYear: 2006,
    replaceCost: 2500,
    notes: null,
  },
  {
    name: 'Paint (Interior)',
    life: null,
    modelYear: null,
    replaceCost: null,
    notes: null,
  },
  {
    name: 'Paint (Exterior)',
    life: null,
    modelYear: null,
    replaceCost: null,
    notes: null,
  },
  {
    name: 'Roof',
    life: null,
    modelYear: null,
    replaceCost: null,
    notes: null,
  },
  {
    name: 'CO2 and Smoke Detectors',
    life: null,
    modelYear: null,
    replaceCost: null,
    notes: null,
  },
];

export var defaultButtonStatus = ['All', 'Incomplete', 'Completed', 'Tenant', 'Expired'];
export type ItemStatus = '' | 'Approved' | 'Urgent' | 'Landlord DIY' | 'On Hold';
export type TaskStatus = '' | 'Complete' | 'Partially Complete' | 'Incomplete' | 'In Progress';
export var defaultSelect: Array<ItemStatus> = ['', 'Approved', 'Urgent', 'Landlord DIY', 'On Hold'];
export var defaultTaskSelect: Array<TaskStatus> = ['', 'Complete', 'Partially Complete', 'Incomplete', 'In Progress'];
export var defaultTypeSelect = ['Routine Assessment', 'Pre-Assessment', 'Move-Out Assessment', 'Move-In Assessment'];
export const selectItemStatus = ['Good', 'Repair', 'Replace', 'Dirty', 'N/A'];
export var defaultCheckbox = [
  { name: 'Good', value: 'Good' },
  { name: 'Repair', value: 'Repair' },
  { name: 'Replace', value: 'Replace' },
  { name: 'Dirty', value: 'Dirty' },
  { name: 'N/A', value: 'NA' },
];

export var defaultFrequency = ['None', 'Quarterly', 'Semi-Annually', 'Annually'];
export var testReportInfo = {
  assessmentType: 'Routine Assessment',
  reportStatus: 'incomplete',
  reportCreated: {
    date: 1628346465747,
    user_name: 'Test User',
  },
  reportEdited: {
    date: 0,
    user_name: '',
  },
  overviewText:
    'The attached report was produced by a certified field technician and thus should not be confused with an inspection performed by a licensed real estate commission inspector. This report is an assessment of the condition of the property on the date shown and only the items listed in the report with notations made are reported on. Certified field technicians are not licensed to check the mechanicals, plumbing, or electrical units in a home and therefore this report will not reflect any issues or functionality of those components. This report is for the use of the property manager and/or owner to see first-hand the condition of the property address as shown on the report. If no safety issues or deficiencies are observed, NO COMMENTS will be made.',
};
