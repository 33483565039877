import { Document, DocumentTemplateLink } from 'app/shared/pages/document/types';
import { PropertyList } from '../../../admin-layout/pages/properties/types';
import { FileTags } from 'app/types';

export namespace OfflineStorage {
  export class Create {
    static readonly type = '[OfflineStorage] StorageCreate';
  }

  export class Load {
    static readonly type = '[OfflineStorage] StorageLoad';
    constructor(public data: any) {}
  }
  export class Save {
    static readonly type = '[OfflineStorage] StorageSave';
  }
  export class Remove {
    static readonly type = '[OfflineStorage] RemoveOffline';
    constructor(public id: string) {}
  }
  export class Sync {
    static readonly type = '[OfflineStorage] SyncWithServer';
  }
  export class Add {
    static readonly type = '[OfflineStorage] AddOffline';
    constructor(public document: Document) {}
  }
  export class Clear {
    static readonly type = '[OfflineStorage] ClearOffline';
  }
  export class Navigate {
    static readonly type = '[Admin] Navigate';
    constructor(public path: string) {}
  }
  export class Logout {
    static readonly type = '[OfflineStorage] Logout';
  }
  export namespace PropertyList {
    export class Add {
      static readonly type = '[OfflineStorage] PropertyListAdd';
      constructor(public data: Array<PropertyList>) {}
    }
  }
  export namespace TemplateList {
    export class Add {
      static readonly type = '[OfflineStorage] TemplateListAdd';
      constructor(public data: Array<DocumentTemplateLink>) {}
    }
  }
  export namespace Tags {
    export class Add {
      static readonly type = '[OfflineStorage] Tags Add';
      constructor(public tags: Array<FileTags>, public type: 'category' | 'images') {}
    }
  }
}
