<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Create {{ type === "work-order" ? "Work Order" : "Assessment" }}</ion-title>
    <ion-buttons slot="end">
      <ion-button id="createDocument" [disabled]="!isValid" (click)="confirm()" [strong]="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-item>
    <ion-select placeholder="Select an template" [(ngModel)]="selectedTemplate">
      <ion-select-option
        [class]="template.name.replaceAll(' ', '_')"
        *ngFor="let template of templates"
        [value]="template">
        {{ template.name }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item *ngIf="type === 'work-order'">
    <ion-label position="floating">Work Order Name</ion-label>
    <ion-input
      class="document-name-input"
      (input)="validate(name)"
      [(ngModel)]="name"
      [name]="'document-name-input'"></ion-input>
  </ion-item>
  <ion-item *ngIf="type === 'assessment'">
    <ion-label>Assessment Type</ion-label>
    <ion-select (ngModelChange)="validate($event)" [(ngModel)]="selectedAssessmentType">
      <ion-select-option *ngFor="let type of assessmentTypes" [value]="type">{{ type.name }}</ion-select-option>
      <ion-select-option [value]="customAssessmentType">{{ customAssessmentType.name }}</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item *ngIf="selectedAssessmentType?.id === customAssessmentType?.id">
    <ion-input
      label="Custom Assessment Type"
      placeholder="Enter custom type"
      (input)="validate(selectedAssessmentType.name)"
      [(ngModel)]="selectedAssessmentType.name"></ion-input>
  </ion-item>
</ion-content>
